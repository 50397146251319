<template>
  <div>
    <v-card class="radius_intab custom_card pa-5">
      <div class="toolbar d-flex">
        <div style="width: 70%">
          <v-row>
            <v-col cols="7">
              <v-text-field
                v-model="find"
                flat
                label="Cari Rumah Sakit"
                class="custom_field"
                background-color="#F1FBFC"
                solo
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                color="blue"
                @keyup="getData"
              />
            </v-col>
            <v-col cols="3">
              <v-menu
                v-model="menuLimit"
                bottom
                :nudge-bottom="55"
                elevation="0"
              >
                <template #activator="{ on, attrs }">
                  <v-card
                    class="pa-3 d-flex align-center"
                    outlined
                    v-bind="attrs"
                    height="100%"
                    v-on="on"
                  >
                    <p class="ma-0 mr-2 color_txt small_txt">
                      Show : {{ limit }}
                    </p>
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-card>
                </template>
                <v-card
                  class="popup_radius"
                  outlined
                  elevation="0"
                >
                  <v-list>
                    <v-list-item
                      link
                      @click="changeLimit(10)"
                    >
                      <v-list-item-title>10</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      @click="changeLimit(50)"
                    >
                      <v-list-item-title>50</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      @click="changeLimit(100)"
                    >
                      <v-list-item-title>100</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div
          class="d-flex justify-end align-center"
          style="width: 40%"
        >
          <v-btn
            depressed
            rounded
            dark
            color="#00b4cc"
            class="text-capitalize"
            @click="dialogCreate = true"
          >
            <p class="my-2">
              Tambah
            </p>
            <v-icon class="ml-2 ">
              mdi-account-plus
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-skeleton-loader
        v-if="loading"
        type="table-tbody"
      />
      <div
        v-if="listData && !loading"
        class="mt-5"
      >
        <v-data-table
          :headers="headers"
          :page.sync="page"
          :items="listData"
          :items-per-page="limit"
          class="elevation-0 color_txt"
          hide-default-footer
          :item-class="row_classes"
          :single-select="false"
        >
          <template #[`item.actions`]="{ item }">
            <div v-if="item">
              <v-btn
                small
                depressed
                color="#00ccb6"
                class="mr-1"
                dark
                @click="viewData(item)"
              >
                Lihat Profile
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="d-flex justify-center pa-3">
        <div class="d-flex align-center pa-2 round_paginate">
          <v-btn
            small
            min-width="10px"
            color="#00b4cc"
            dark
            class="pa-2 custom_shadow"
            @click="pagination('prev')"
          >
            <v-icon small>
              mdi-arrow-left
            </v-icon>
          </v-btn>
          <div class="mx-3">
            <p class="ma-0 color_default">
              {{ page }} of {{ total_page }}
            </p>
          </div>
          <v-btn
            small
            min-width="10px"
            color="#00b4cc"
            dark
            class="pa-2 custom_shadow"
            @click="pagination('next')"
          >
            <v-icon small>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>
      </div>
      <div class="pa-3">
        <detail-instance
          ref="viewData"
          :dialog-alert="dialogAlert"
          :item="dataItem"
          @close="dialogAlert = false"
        />
      </div>
    </v-card>
    <div class="pa-2">
      <CreateFasyankesUser
        :tab-name="'Rumah Sakit'"
        :dialog-create="dialogCreate"
        @close="dialogCreate = false"
        @refetch="refetch()"
      />
      <UpdateUser
        ref="update"
        :dialog-update-user="dialogUpdateUser"
        :data-user="dataUser"
        @close="dialogUpdateUser = false"
        @refetch="refetch()"
        @pwd="showPwd"
      />
      <DeleteUser
        :dialog-delete-user="dialogDeleteUser"
        :data-user="dataUser"
        @refetch="refetch()"
        @close="dialogDeleteUser = false"
      />
      <ChangePassword
        :dialog-change-pwd="dialogChangePwd"
        :data-user="dataUser"
        @close="dialogChangePwd = false"
        @walrus="backFromChangePwd"
      />
      <ChangeStatusUser
        :dialog-change-status="dialogChangeStatus"
        :data-user="dataUser"
        @close="dialogChangeStatus = false"
      />
    </div>
  </div>
</template>

<script>
import DetailInstance from "./detailInstance.vue";
import CreateFasyankesUser from "../Pengguna/Create/createFasyankesUser.vue";
import DeleteUser from "./../Pengguna/Delete/deleteUser.vue";
import ChangePassword from "./../Pengguna/Update/changePassword.vue";
import ChangeStatusUser from "./../Pengguna/Update/changeStatusUser.vue";
import UpdateUser from "./../Pengguna/Update/updateUser.vue";
export default {
  name: "ListRs",
  components: {
    DetailInstance, CreateFasyankesUser, DeleteUser, ChangePassword, ChangeStatusUser, UpdateUser,
  },
  props: {
    tabName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dataItem: null,
      listData: null,
      role: "",
      page: 1,
      limit: 10,
      find: "",
      menu: false,
      menuLimit: false,
      dataUser: null,
      dialogAlert: false,
      dialogDeleteUser: false,
      dialogUpdateUser: false,
      dialogChangePwd: false,
      dialogChangeStatus: false,
      dialogCreate: false,
      headers: [
        {
          text: "Kode Rumah Sakit",
          align: "start",
          value: "kode_rs",
        },
        {
          text: "Nama Rumah Sakit",
          align: "start",
          value: "nama_rs",
        },
        {
          text: "Provinsi",
          align: "start",
          value: "provinsi_name",
        },
        { text: "Kabupaten/Kota", value: "kabupaten_name" },
        // { text: "Kecamatan", value: "kecamatan_name" },
        { text: "Aksi", value: "actions" },
      ],
      isNext: "",
      isPrev: "",
      total_page: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    refetch() {
      this.dialogCreate = false;
    },
    backFromChangePwd() {
      this.dialogChangePwd = false;
      this.dialogUpdateUser = true;
    },
    changeLimit(val) {
      this.limit = val;
      this.getData();
    },
    pagination(state) {
      if (state == "next") {
        if (this.isNext) {
          this.page++;
          this.getData();
        }
      } else {
        if (this.isPrev) {
          this.page--;
          this.getData();
        }
      }
    },
    getData() {
      this.loading = true;
      let data = {
        type: 1,
        find: this.find,
        limit: this.limit,
        page: this.page,
      };
      this.$store
        .dispatch("dataFasyankes/searchData", data)
        // .dispatch("dataFasyankes/getFasyankes", data)
        .then((data) => {
          this.listData = data.data.data;
          this.total_page = Math.ceil(data.data.total / this.limit);
          this.loading = false;
          this.isNext = data.data.next_page_url;
          this.isPrev = data.data.prev_page_url;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    findData() {
      this.loading = true;
      let data = {
        type: 1,
        find: this.find,
      };
      this.$store.dispatch("dataFasyankes/searchData", data).then((data) => {
        this.listData = data.data.data;
        this.loading = false;
      });
    },
    showPwd() {
      this.dialogUpdateUser = false;
      this.dialogChangePwd = true;
    },
    viewData(item) {
      this.dataItem = item;
      this.$refs.viewData.getData(1, item.profilrs_id);
      this.dialogAlert = true;
    },
    delUser(item) {
      console.log(item);
      this.dataUser = item;
      this.dialogDeleteUser = true;
    },
    changeStatus(item) {
      this.dataUser = item;
      this.dialogChangeStatus = true;
    },
    viewUser(item) {
      console.log(item);
      this.dataUser = item;
      this.dialogUpdateUser = true;
      this.$refs.update.getPerson(item.id);
    },
    row_classes(item) {
      let idx = this.listData.indexOf(item);
      if (idx % 2 == 0) {
        return "strip_blue"; //can also return multiple classes e.g ["orange","disabled"]
      }
    },
  },
};
</script>

<style scoped>
.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
</style>