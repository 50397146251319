<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <blue-circle class="mr-2" />
      <h3 class="color_default">Data Fasyankes</h3>
    </div>
    <v-tabs v-model="tab" background-color="#cdf0f7" class="px-2 pb-2">
      <v-tab
        href="#tab1"
        class="text-capitalize mr-3 header_tab"
        active-class="active_tab"
        ><h3>Rumah Sakit</h3></v-tab
      >
      <v-tab
        href="#tab2"
        class="text-capitalize mr-3 header_tab"
        active-class="active_tab"
        ><h3>Puskesmas</h3></v-tab
      >
    </v-tabs>
    <v-tabs-items v-model="tab" style="background: none" class="px-2">
      <v-tab-item value="tab1">
        <ListRs :tabName="'Rumah Sakit'" />
        <div style="height: 100px"></div>
      </v-tab-item>
      <v-tab-item value="tab2">
        <ListPuskesmas />
        <div style="height: 100px"></div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import BlueCircle from "@/components/General/BlueCircle";
import ListPuskesmas from "../../components/dataFasyankes/listPuskesmas.vue";
import ListRs from "../../components/dataFasyankes/listRs.vue";
export default {
  components: {
    BlueCircle,
    ListRs,
    ListPuskesmas,
  },
  computed: {},
  data() {
    return {
      tab: "tab1",
      updateLoginStatus: false,
      loginActive: true,
    };
  },

  methods: {},
};
</script>

<style scoped>
.padd_content {
  padding: 40px 50px;
}
</style>