import { render, staticRenderFns } from "./listPuskesmas.vue?vue&type=template&id=8dca9ac8&scoped=true"
import script from "./listPuskesmas.vue?vue&type=script&lang=js"
export * from "./listPuskesmas.vue?vue&type=script&lang=js"
import style0 from "./listPuskesmas.vue?vue&type=style&index=0&id=8dca9ac8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dca9ac8",
  null
  
)

export default component.exports